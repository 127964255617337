<template>
  <div class="form-row">
    <sgv-input-text
      class="col-12 col-md-6"
      label="ชื่อ"
      v-model="formData.code"
      :disabled="$auth.disabled(method)"
      :validations="[
        {text: 'required!', value: v.formData.code.$dirty && v.formData.code.$error}
      ]">
    </sgv-input-text>

    <sgv-input-text
      class="col-12 col-md-6"
      label="ชื่อที่ใช้แสดง"
      v-model="formData.name"
      :disabled="$auth.disabled(method)"
      :validations="[
        {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
      ]">
    </sgv-input-text>

    <sgv-input-number
      class="col-6 col-md-3"
      label="จำนวน (-1 ปุ่มเปิด/ปิด)"
      v-model="formData.qty"
      minus
      :disabled="$auth.disabled(method)">
    </sgv-input-number>

    <UnitFormInput
      label="หน่วย"
      class="col-6 col-md-3"
      :disabled="$auth.disabled(method)"
      :unitType="unitType"
      select="id"
      templateType="ChronowoodthUnit"
      v-model="formData.unitId"
      :validations="[
        {text: 'required!', value: v.formData.unitId.$dirty && v.formData.unitId.$error}
      ]">
    </UnitFormInput>

    <sgv-input-currency
      class="col-6 col-md-6"
      label="ราคา (0 = ฟรี, -1 ขอใบเสนอราคา)"
      v-model="formData.price"
      :disabled="$auth.disabled(method)"
      minus>
    </sgv-input-currency>

    <sgv-input-date
      class="col-6"
      label="หมดเขต (ถ้ามี)"
      v-model="formData.dueAt"
      :disabled="$auth.disabled(method)">
    </sgv-input-date>

    <sgv-input-currency
      class="col-6"
      label="ราคาพิเศษ (0 = ฟรี, -1 ขอใบเสนอราคา)"
      minus
      v-model="formData.duePrice"
      :disabled="$auth.disabled(method)">
    </sgv-input-currency>
  </div>
</template>

<script>
import UnitFormInput from '@/views/chronowoodth_core/UnitFormInput.vue'

export default {
  props: {
    priceId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    unitType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  components: {
    UnitFormInput
  }
}
</script>

<style lang="css">
</style>
