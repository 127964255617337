import gql from 'graphql-tag'


const listResponse = `
  id type code name price duePrice dueAt qty
  unitId unit {id name}
`

export const LIST_PRICE = (templateType) => gql`query LIST_PRICE ($priceType: String!, $q: ${templateType}FilterInput) {
  prices: list${templateType} (priceType: $priceType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type code name price duePrice dueAt unitId qty
`

export const DETAIL_PRICE = (templateType) => gql`query DETAIL_PRICE ($priceType: String!, $priceId: Int!) {
  price: detail${templateType} (priceType: $priceType, priceId: $priceId) {${detailResponse}}
}`

export const CREATE_PRICE = (templateType) => gql`mutation CREATE_PRICE ($priceType: String!, $input: ${templateType}CreateInput!) {
  createPrice: create${templateType} (priceType: $priceType, input: $input) {${detailResponse}}
}`

export const UPDATE_PRICE = (templateType) => gql`mutation UPDATE_PRICE ($priceType: String!, $priceId: Int!, $input: ${templateType}UpdateInput!) {
  updatePrice: update${templateType} (priceType: $priceType, priceId: $priceId, input: $input) {${detailResponse}}
}`

export const DESTROY_PRICE = (templateType) => gql`mutation DESTROY_PRICE ($priceType: String!, $priceId: Int!) {
  destroyPrice: destroy${templateType} (priceType: $priceType, priceId: $priceId) {id}
}`