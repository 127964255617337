<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">รายการ</h3>
      </div>
    </div>

    <hr class="my-3">

    <div class="row">
      <div class="col-12">
        <sgv-form :method.sync="method" :options="options">
          <DetailForm
            :priceId="priceId"
            :priceType="priceType"
            :unitType="unitType"
            :templateType="templateType"
            :formData="formData"
            :method="method"
            :v="$v">
          </DetailForm>
        </sgv-form>
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm.vue'
import {
  DETAIL_PRICE,
  CREATE_PRICE,
  UPDATE_PRICE,
  DESTROY_PRICE,
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: this.formData.title
    }
  },
  props: {
    priceId: {
      type: Number,
      required: true
    },
    priceType: {
      type: String,
      required: true
    },
    unitType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      method: 'info',
      listView: `ChronowoodthPrice${this.$form.capitalize(this.priceType)}List`,
      rKey: `ChronowoodthPrice${this.$form.capitalize(this.priceType)}Detail`,
      formData: {
        code: '',
        name: '',
        price: -1,
        dueAt: null,
        duePrice: -1,
        unitId: null,
        qty: 0
      }
    }
  },
  validations: {
    formData: {
      code: { required },
      name: { required },
      unitId: { required },
    }
  },
  computed: {
    options () {
      return [
        {text: 'เพิ่ม', variant: 'success', method: 'add', func: this.createData, disabled: true},
        {text: 'แก้ไข', variant: 'warning', method: 'edit', func: this.updateData, disabled: !this.$auth.hasRole(`chronowoodthPrice:${this.priceType}:add`) || this.method === 'add'},
        {text: 'ลบ', variant: 'danger', method: 'delete', func: this.destroyData, disabled: !this.$auth.hasRole(`chronowoodthPrice:${this.priceType}:add`) || this.method === 'add'},
        {text: 'กลับ', variant: 'primary', func: this.closeForm, header: true, align: 'right'},
      ]
    }
  },
  methods: {
    setFormData (v) {
      this.formData.code = v.code
      this.formData.name = v.name
      this.formData.price = v.price
      this.formData.dueAt = this.$date.format(v.dueAt).date
      this.formData.duePrice = v.duePrice
      this.formData.unitId = v.unitId
      this.formData.qty = v.qty
    },
    serializeInput (v) {
      return {
        code: v.code,
        name: v.name,
        price: v.price,
        dueAt: this.$date.format(v.dueAt).utc,
        duePrice: v.duePrice,
        unitId: v.unitId,
        qty: v.qty,
      }
    },
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_PRICE(this.templateType),
        variables: {
          priceType: this.priceType,
          priceId: this.priceId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.price)})
      .catch(err => {this.$toasted.global.error(err)})
      .finally(() => {this.isLoaded = true})
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_PRICE(this.templateType),
        variables: {
          priceType: this.priceType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, priceId: res.data.createPrice.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_PRICE(this.templateType),
        variables: {
          priceType: this.priceType,
          priceId: this.priceId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updatePrice)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_PRICE(this.templateType),
        variables: {
          priceType: this.priceType,
          priceId: this.priceId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.priceId === 0) {
      this.method = 'add'
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
  }
}
</script>

<style lang="css" scoped>
</style>
